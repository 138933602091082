import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { NavbarDropdown } from './NavbarDropdown';
import { Wrapper } from '../Wrapper';
import { StaticImage } from 'gatsby-plugin-image';
import { useAuth } from '../../../hooks/useAuth';
import { getUser, isBrowser, isLoggedIn } from '../../../services/auth';
import { useLocation } from '@reach/router';
import {
  DesktopComputerIcon,
  ExclamationIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import { NavbarMenuItem } from './NavbarMenuItem';
import { ClipboardCheckIcon } from '@heroicons/react/solid';

type Props = {
  disableEmphasize?: boolean;
};

export function Navbar({ disableEmphasize }: Props) {
  const { pathname } = useLocation();
  const [showUser, setShowUser] = useState<boolean>(false);
  const { checkUser, signOut } = useAuth();

  const [emphasizeNav, setEmphasizeNav] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      window.onfocus = function () {
        checkUser();
      };
    }
  }, []);

  useEffect(() => {
    if (!!getUser().name) setShowUser(true);
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      window.addEventListener('scroll', emphasizeNavbar);
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', emphasizeNavbar);
    };
  }, []);

  async function signOutHandler() {
    setShowUser(false);
    await signOut();

    if (pathname !== '/') {
      isBrowser() && navigate('/');
    }
  }

  function emphasizeNavbar() {
    if (!disableEmphasize) {
      if (window.scrollY >= 10) return setEmphasizeNav(true);
      setEmphasizeNav(false);
    }
  }

  function isNavActive(path: string): boolean {
    const pathArray = pathname.split('/');
    const pathIndex = 1;
    return pathArray[pathIndex] === path;
  }

  return (
    <header
      className={`${
        emphasizeNav ? 'bg-primary-1 shadow bg-opacity-90' : ''
      } shadow sticky top-0 z-40 transition duration-500 ease-in-out bg-white`}
      style={{ height: 70 }}
    >
      <Wrapper>
        <section className="flex flex-row">
          <div
            className={emphasizeNav ? `mx-auto` : `flex-grow flex items-center`}
          >
            {emphasizeNav ? (
              <StaticImage
                className="cursor-pointer hover:opacity-80"
                quality={95}
                formats={['webp', 'auto', 'avif']}
                width={88}
                height={30}
                src={`../../../assets/images/nstw_nocap.png`}
                alt="NSTW Logo"
                placeholder="tracedSVG"
                onClick={() => navigate('/')}
              />
            ) : (
              <section className="flex flex-row space-x-2">
                <StaticImage
                  className="cursor-pointer hover:opacity-80"
                  quality={95}
                  formats={['webp', 'auto', 'avif']}
                  width={40}
                  height={40}
                  src={`../../../assets/images/logo.png`}
                  alt="DOST Logo"
                  placeholder="tracedSVG"
                  onClick={() => navigate('/')}
                />
                <StaticImage
                  className="cursor-pointer hover:opacity-80"
                  quality={95}
                  formats={['webp', 'auto', 'avif']}
                  width={75}
                  height={40}
                  src={`../../../assets/images/nstw_logo.png`}
                  alt="NSTW Logo"
                  placeholder="tracedSVG"
                  onClick={() => navigate('/')}
                />
              </section>
            )}
          </div>

          {!emphasizeNav ? (
            <div className="flex items-center justify-center space-x-2">
              <section className="hidden md:flex">
                <NavbarMenuItem path="/" title="Home" />
                {!isLoggedIn() || (isLoggedIn() && !getUser().isCheckpoint) ? (
                  <>
                    <NavbarMenuItem
                      path="/about"
                      title="About"
                      active={isNavActive('about')}
                    />
                    <NavbarMenuItem
                      path="/activities"
                      title="Activities"
                      active={isNavActive('activities')}
                    />
                    <NavbarMenuItem
                      path="/webinars"
                      title="Webinars"
                      active={isNavActive('webinars')}
                    />
                    <NavbarMenuItem
                      path="/contests"
                      title="Contests"
                      active={isNavActive('contests')}
                    />
                  </>
                ) : null}
              </section>

              {isLoggedIn() && showUser ? (
                <>
                  {getUser().isCheckpoint ? (
                    <NavbarMenuItem
                      path="/checkpoint/"
                      title="Complete Registration"
                      icon={
                        <ExclamationIcon className="md:ml-2 w-5 h-5 text-primary-1" />
                      }
                    />
                  ) : null}
                  <NavbarDropdown destroyAuth={signOutHandler} />

                  {!pathname.match(/\/exhibit/) ? (
                    <NavbarMenuItem
                      path="/exhibit/"
                      title="VIRTUAL EXHIBIT"
                      emphasize
                      icon={
                        <DesktopComputerIcon className="block md:hidden md:ml-2 w-5 h-5" />
                      }
                    />
                  ) : (
                    <a
                      href="https://bit.ly/NSTW2021EXHIBITEVAL"
                      target="_blank"
                      className="flex flex-row items-center rounded-lg font-medium text-sm px-3 py-2 md:px-4 uppercase bg-primary-1 text-white hover:opacity-80"
                    >
                      <span className="hidden md:block">Evaluate</span>
                      <ClipboardCheckIcon className="block md:hidden md:ml-2 w-5 h-5" />
                    </a>
                  )}
                </>
              ) : (
                <>
                  <NavbarMenuItem path="/auth/" title="Sign In" />
                  <NavbarMenuItem
                    path="/auth?join=true"
                    title="Join Now"
                    emphasize
                    icon={
                      <LockClosedIcon className="block md:hidden w-5 h-5" />
                    }
                  />
                </>
              )}
            </div>
          ) : (
            <React.Fragment />
          )}
        </section>
      </Wrapper>
    </header>
  );
}
