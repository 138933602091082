import React, { useEffect } from 'react';
import { LayoutProps } from '../../interfaces/Props';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export function PublicLayout({
  children,
  disableEmphasize,
  disableFooter,
}: LayoutProps) {
  useEffect(() => {
    var Tawk_API: any = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/617a591786aee40a5738c1ba/1fj2uo1js';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      if (s0.parentNode !== null) s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <section className="flex flex-col min-h-screen">
      <Navbar disableEmphasize={disableEmphasize} />
      <main className="flex-1">{children}</main>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {!disableFooter ? <Footer /> : null}
    </section>
  );
}
