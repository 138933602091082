import React from 'react';
import { getUser } from '../../../services/auth';

export function NavbarAvatar() {
  return (
    <img
      src={
        !!getUser().picture && getUser().picture !== null
          ? (getUser().picture as string)
          : `https://avatars.dicebear.com/api/identicon/${getUser().id}.svg`
      }
      height={30}
      width={30}
      alt={getUser().name}
      className="rounded-full border border-gray-300"
    />
  );
}
