import React from 'react';
import { Link } from 'gatsby';
import { Wrapper } from './Wrapper';
import FacebookIcon from '../../assets/svg/socials/facebook.svg';
import TwitterIcon from '../../assets/svg/socials/twitter.svg';
import YouTubeIcon from '../../assets/svg/socials/youtube.svg';
import WebIcon from '../../assets/svg/socials/web.svg';
import { StaticImage } from 'gatsby-plugin-image';

export function Footer() {
  return (
    <footer className="w-full mt-5">
      <section className="bg-primary-2 py-8 text-white">
        <Wrapper>
          <section className="text-center">
            <section className="flex flex-col md:flex-row md:justify-between md:items-center md:mb-5">
              <section className="flex flex-col">
                <section className="flex flex-row justify-center items-center md:justify-start space-x-2 mb-5">
                  <StaticImage
                    className="mt-5"
                    quality={95}
                    formats={['webp', 'auto', 'avif']}
                    width={50}
                    height={50}
                    src={`../../assets/images/logo.png`}
                    alt="DOST Logo"
                    placeholder="tracedSVG"
                  />
                  <StaticImage
                    quality={95}
                    height={60}
                    width={120}
                    formats={['webp', 'auto', 'avif']}
                    src={`../../assets/images/sftp_logo.png`}
                    alt="Banner Icons"
                    placeholder="tracedSVG"
                  />
                </section>

                <section className="text-sm mb-5 md:text-lg font-medium">
                  National Science & Technology Week in Region 1
                </section>
              </section>

              <section className="flex flex-col">
                <p className="uppercase text-sm font-md font-medium">
                  Connect with Us
                </p>
                <section className="flex flex-row space-x-4 mb-5 items-center justify-center">
                  <a
                    href="https://region1.dost.gov.ph/"
                    target="_blank"
                    className="block hover:bg-primary-1 hover:opacity-90 py-2 px-2 rounded-full"
                  >
                    <WebIcon />
                  </a>

                  <a
                    href="https://facebook.com/dostro1"
                    target="_blank"
                    className="block hover:bg-primary-1 hover:opacity-90 py-2 px-2 rounded-full"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="block hover:bg-primary-1 hover:opacity-90 py-2 px-2 rounded-full"
                  >
                    <YouTubeIcon />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="block hover:bg-primary-1 hover:opacity-90 py-2 px-2 rounded-full"
                  >
                    <TwitterIcon />
                  </a>
                </section>
              </section>
            </section>

            <section className="flex flex-col md:flex-row md:justify-between md:items-center">
              <section className="flex flex-row space-x-4 justify-center mb-5 md:mb-0 text-sm font-medium">
                <Link className="hover:text-primary-1" to="/terms-of-service">
                  Terms of Service
                </Link>
                <Link className="hover:text-primary-1" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </section>

              <span className="text-sm">
                &copy; {new Date().getFullYear()} Department of Science and
                Technology Region 1
              </span>
            </section>
          </section>
        </Wrapper>
      </section>
    </footer>
  );
}
