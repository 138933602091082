import React from 'react';
import { LayoutProps } from '../../../interfaces/Props';

export function Wrapper({ children }: LayoutProps) {
  return (
    <section
      className="flex 
                flex-col
                justify-center
                h-full
                py-2 px-6
                sm:px-8
                md:px-12 
                lg:px-16
                max-w-9xl mx-auto"
    >
      {children}
    </section>
  );
}
