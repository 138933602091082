import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { NavbarAvatar } from './NavbarAvatar';
import { Link, navigate } from 'gatsby';
import { getUser } from '../../../services/auth';
import { NavbarMenuItem } from './NavbarMenuItem';
import { UserRoleEnum } from '../../../graphql/__types';
import { removeAlreadyEvaluated } from '../../../services/ads';

interface Props {
  destroyAuth(): void;
}

export function NavbarDropdown({ destroyAuth }: Props) {
  function signOut() {
    destroyAuth();
    removeAlreadyEvaluated();
  }

  return (
    <Menu as="section" className="relative inline-block text-left">
      <Menu.Button className="flex space-x-1 items-center">
        <NavbarMenuItem
          path="#"
          icon={<ChevronDownIcon className="w-5 h-5" aria-hidden="true" />}
          isProfile
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-72 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <section className="px-1 py-1">
            <section className="flex space-x-4 px-4 py-4 items-start">
              {getUser() !== null && (
                <>
                  <NavbarAvatar />
                  <section className="overflow-hidden flex flex-col">
                    <span className="text-md text-black">{getUser().name}</span>
                    <span className="text-xs">{getUser().email}</span>

                    <section className="flex flex-row space-x-2">
                      <Link
                        to={`/profile/${getUser().id}`}
                        className="underline text-sm mt-3 font-bold hover:text-primary-1"
                      >
                        View Profile
                      </Link>
                    </section>
                  </section>
                </>
              )}
            </section>
          </section>
          <section className="px-1 py-1">
            {getUser().role === UserRoleEnum.Administrator ? (
              <>
                <Menu.Item>
                  <button
                    className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                    onClick={() => navigate('/dashboard')}
                  >
                    Dashboard
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                    onClick={() => navigate('/users')}
                  >
                    Users
                  </button>
                </Menu.Item>
              </>
            ) : null}

            <section className="md:hidden">
              <Menu.Item>
                <button
                  className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                  onClick={() => navigate('/activities')}
                >
                  Activities
                </button>
              </Menu.Item>

              <Menu.Item>
                <button
                  className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                  onClick={() => navigate('/webinars')}
                >
                  Webinars
                </button>
              </Menu.Item>

              <Menu.Item>
                <button
                  className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                  onClick={() => navigate('/contests')}
                >
                  Contests
                </button>
              </Menu.Item>
            </section>
          </section>
          <section className="px-1 py-1">
            <Menu.Item>
              <button
                className={`group flex rounded-md items-center font-medium w-full px-4 py-2 text-sm hover:bg-gray-100`}
                onClick={signOut}
              >
                Sign Out
              </button>
            </Menu.Item>
          </section>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
