import { APP_ALREADY_EVALUATED } from '../config/constants';

export const setAlreadyEvaluated = () =>
  localStorage.setItem(APP_ALREADY_EVALUATED, 'true');

export const removeAlreadyEvaluated = () =>
  localStorage.removeItem(APP_ALREADY_EVALUATED);

export const getAlreadyEvaluated = () =>
  localStorage.getItem(APP_ALREADY_EVALUATED);
