import { Link } from 'gatsby';
import React from 'react';
import { NavbarAvatar } from './NavbarAvatar';

type Props = {
  title?: string;
  path: string;
  icon?: JSX.Element | JSX.Element[];
  emphasize?: boolean;
  active?: boolean;
  isProfile?: boolean;
  responsive?: boolean;
};

export function NavbarMenuItem({
  title,
  path,
  icon,
  active,
  emphasize,
  isProfile,
}: Props) {
  return (
    <Link
      to={path}
      className={`flex flex-row items-center rounded-lg font-medium text-sm px-3 py-2 md:px-4 uppercase ${
        emphasize
          ? 'bg-primary-1 text-white hover:opacity-80'
          : 'hover:bg-gray-100'
      }
        ${active ? 'text-primary-1' : ''}
      `}
    >
      {title ? <span className="hidden md:block">{title}</span> : null}
      {isProfile ? <NavbarAvatar /> : null}
      {icon}
    </Link>
  );
}
